import React from 'react'

type Props = {}

const Banner = (props: Props) => {
    return (
        <div className='w-full'>
            <div className="container relative h-[400px] lg:h-[850px] flex overflow-hidden">
                <div className='absolute w-full h-full'>
                    <img src="assets/TV24-banner.png" className='w-full h-full' alt="" />
                </div>
                <div className='w-2/3 h-full'></div>
                <div className='w-1/3 h-full'>
                    <div className='relative w-full h-full'>
                        <img src="assets/TV24-tachnen2.png" className='w-[1200px] h-full absolute -bottom-[3%] -right-[10%] loop-Image' alt="" />
                    </div>
                </div>
            </div>
            <div className='w-full text-center mt-5 '>
                <p className='text-3xl break-words'>CA AMCgKXQZBqeZdQYtbKEZNK2bEzddZS2Gb5BhyNq21uyP</p>
            </div>
        </div>
    )
}

export default Banner