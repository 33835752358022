import React from 'react';
import { MdDone } from "react-icons/md";
import Slider from "react-slick";
import lodash from "lodash";

const HowToBuy = () => {
    const settings = {
        infinite: true,
        autoplay: true,
        arrows: false,
        dots: false,
        pauseOnHover: true,
        speed: 3000,
        autoplaySpeed: 0,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
    };

    return (
        <div className='w-full'>
            <div className='container mx-auto'>
                <div className='w-full text-center mt-16 md:mt-0'>
                    <h1 className='text-5xl font-bold'>HOW TO BUY</h1>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-8'>
                    <div className='w-full'>
                        <div className='w-full text-end'>
                            <h1 className='font-bold text-4xl'>1</h1>
                        </div>
                        <div className='w-full text-center'>
                            <h1 className='font-bold text-4xl pt-4'>Create A Wallet</h1>
                            <p className='pt-4'>Download OKX wallet or your wallet of choice from the app store or Google Play for free. Desktop users, download your browser's extension by going to OKX or Phantom.</p>
                        </div>
                    </div>
                    <div className='w-full'>
                        <div className='w-full text-end'>
                            <h1 className='font-bold text-4xl'>2</h1>
                        </div>
                        <div className='w-full text-center'>
                            <h1 className='font-bold text-4xl pt-4'>Get some $SOL</h1>
                            <p className='pt-4'>Have $SOL in your wallet to switch to TV24. If you don’t have any $SOL, you can buy directly on Phantom, transfer from another wallet, or buy on another exchange and send it to your wallet.</p>
                        </div>
                    </div>
                    <div className='w-full'>
                        <div className='w-full text-end'>
                            <h1 className='font-bold text-4xl'>3</h1>
                        </div>
                        <div className='w-full text-center'>
                            <h1 className='font-bold text-4xl pt-4'>Go to OKX DEX</h1>
                            <p className='pt-4'>Connect to OKX decentralized exchange. Go to OKX in your browser. Connect your wallet. Paste the TV24 token address into OKX, select TV24, and confirm. When Then you get a prompt for a wallet signature, sign.</p>
                        </div>
                    </div>
                    <div className='w-full bg-[#2723FF] rounded-3xl p-5'>
                        <div className='w-full text-end'>
                            <h1 className='flex justify-end items-center'>
                                <MdDone className='text-3xl text-white' />
                            </h1>
                        </div>
                        <div className='w-full text-center'>
                            <h1 className='font-bold text-4xl pt-4 text-white'>TV24</h1>
                            <p className='pt-4 text-white'>Participate in the TV24 community. Share memes, talk to other Holders in Telegram and participate in community events</p>
                        </div>
                    </div>
                </div>
                <div className='w-full pt-4'>
                    <Slider {...settings}>
                        {lodash.range(1, 8).map((val) => (
                            <div className="px-4 py-3" key={val}>
                                <img src="assets/Flag.avif" className='w-[100px] h-[50px]' alt="" />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default HowToBuy;